const makeTradeinnUrl = (productUrl, epi) => {
  if (!productUrl) {
    throw new Error('Missing input');
  }

  return `https://tnkdbf.tradeinn.com/ts/i5534887/tsc?typ=r&amc=con.blbn.494748.503573.14119786&smc=${epi ??
    ''}&rmd=3&trg=${encodeURIComponent(productUrl)}`;
};

export { makeTradeinnUrl };
